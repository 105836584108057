// Email

import React, { forwardRef } from "react";
import { useFormik } from 'formik';
import styles from "./Form.module.scss";
import Cta from "../Cta";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import formstyles from '../Form/Form.module.scss';
import * as Select from '@radix-ui/react-select';
import { ChevronDownIcon, CheckIcon } from "@radix-ui/react-icons";


const SelectItem = forwardRef(({ children, className, ...props }, forwardedRef) => {
	return (
	  <Select.Item className="SelectItem" {...props} ref={forwardedRef}>
		<Select.ItemText>{children}</Select.ItemText>
		<Select.ItemIndicator className="SelectItemIndicator">
		  <CheckIcon />
		</Select.ItemIndicator>
	  </Select.Item>
	);
});


const Newsletter = props => {
	const formik = useFormik({
		initialValues: {
			role: 'advertiser',
			email: '',
			linkedin: '',
		},
		onSubmit: async (values) => {
			await fetch('https://usebasin.com/f/4c73e4eeb1d1', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json; charset=UTF-8' },
				body: JSON.stringify(values)
			});
		},
	});

	return (
		<form action="https://usebasin.com/f/4c73e4eeb1d1" method="POST">
			<Stack spacing={1.5} mb={1} className={styles.inputWrapper}>
			<div className={formstyles.input}>
				<label className={formstyles.defaultLabel}>What is your role?*</label>
					<Select.Root
						onValueChange={item => formik.setFieldValue('role', item)}
						id="role"
						name="role"
						required={true}
					>
						<Select.Trigger className="SelectTrigger">
							<Select.Value placeholder="Select your role" />
							<Select.Icon className="SelectIcon">
								<ChevronDownIcon />
							</Select.Icon>
						</Select.Trigger>
						<Select.Portal style={{ zIndex: "9999" }} className="Portal">
							<Select.Content className="SelectContent">
								<Select.Viewport className="SelectViewport">
									<SelectItem value="agency">Agency</SelectItem>
									<SelectItem value="podcast-creator">Podcast Creator</SelectItem>
									<SelectItem value="publisher">Publisher</SelectItem>
									<SelectItem value="third-party-provider">Third-Party Provider</SelectItem>
									<SelectItem value="advertiser">Advertiser/Brand</SelectItem>
									<SelectItem value="media">Media/Press</SelectItem>
									<SelectItem value="other">Other</SelectItem>
								</Select.Viewport>
							</Select.Content>
						</Select.Portal>
					</Select.Root>
				</div>
				<TextField type="text" id="linkedin" name="linkedin" label="LINKEDIN" required={true} />
				<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required={true} />
				<Cta type="button" color={props.background_color}>
					<input type="submit" />
				</Cta>
			</Stack>
		</form>
	);
};

export default Newsletter;
